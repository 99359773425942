import request from '@/plugins/axios'

// 登录
export const apiLogin = data => request.post('/account/login', {...data, client: 5})

// 退出登录
export const apiLogout = () => request.post('/account/logout')

// 用户列表
export const apiChatUserList = params => request.get('/chat/user', {params})

// 聊天记录
export const apiChatHistory = params => request.get('/member/chatRecord', {params})

// 客服列表
export const apiServiceList = params => request.get('/chat/online', {params})


// 快捷回复
export const apiReplyList = params => request.get('/chat/reply', {params})


// 获取用户详情
export const apiUserInfo = params => request.get('/api/chat/userInfo', {params})

// 获取订单列表
export const apiUserOrder = params => request.get('/chat/order', {params})

// 文件上传
export const apiUploadFile = data => request.post('/api/file/formimage', data)

// 获取客服详情
export const apiKefuDetail = () => request.get('/api/chat/kefuInfo')

// 获取配置信息
export const apiGetConfig = () => request.get('/chat/config')
