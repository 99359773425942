<template>
    <div id="app">
        <router-view v-if="routerAlive" />
    </div>
</template>

<script>

import { apiGetConfig } from '@/api/app'
export default {
    data() {
        return {
            routerAlive: true
        }
    },
    provide() {
        return {
            reload: this.reload
        }
    },
    methods: {
        reload() {
            this.routerAlive = false
            this.$nextTick(() => {
                this.routerAlive = true
            })
        },
    },
    created(){

    }
}
</script>

<style lang="scss">
@import './styles/index.scss';
#app {
    min-width: 1200px;
}
</style>
