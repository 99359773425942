// 开发环境域名

// const host_development = 'http://api-kefu-xinnai.lcsnfm.com.cn'
// const host_production = 'http://api-kefu-xinnai.lcsnfm.com.cn'


const host_development = 'http://api-www-xinnai.lcsnfm.com.cn'
const host_production = 'http://api-www-xinnai.lcsnfm.com.cn'


export default {
    // 版本
    version: '2.1.3.20221123',
    baseURL: process.env.NODE_ENV == 'production' ? host_production : host_development,
    // ChatWss: 'wss://likeshopb2b2cchat.yixiangonline.com'
}


